#header {
  .search-widget {
    form {
      input[type="text"] {
        border: 1px solid $cc-dore;
        color: $cc-dore;
        font-size: 15px;
        padding: 3px 40px 2px 20px;
        &::placeholder {
          color: $cc-dore;
          opacity: .7;
        }
      }
      button[type="submit"] {
        background-image: url(../img/picto-loupe.png) !important;
        background-color: transparent !important;
        background-repeat: no-repeat !important;
        background-position: left !important;
        text-indent: -9999px;
        width: 0;
      }
    }
  }
}
.ui-autocomplete {
  z-index: 999 !important;
}

@media (max-width: $lg-down) {
  #header {
    #search_widget {
      padding-top: 0;
      position: absolute;
      top: -42px;
      left: 50px;
      width: calc(100% - 145px);
      .searchtoggle {
        margin-top: 0;
        form {
          input[type="text"] {
            padding-right: 30px;
          }
        }
      }
    }
  }
}