.product-flags {
  .on-sale,
  .new {
    color: $cc-blanc;
    display: block;
    font-size: 12px;
    position: absolute;
    letter-spacing: 0.05em;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 14px;
    padding: 3px 5px;
    background: $cc-dore;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 10px;
    height: 30px;
    line-height: 30px;
    &::before {
      width: 30px;
      height: 30px;
      background-color: $cc-noir;
      display: block;
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      background-position: center 0;
      background-repeat: no-repeat;
    }
  }
  .on-sale {
    top: 35px;
    &::before {
      background-image: url(../img/flag-promo.png);
    }
  }
  .new {
    &::before {
      background-image: url(../img/flag-new.png);
    }
  }
}