#cookieNotice {
  #cookieNoticeContent {
    table {
      tr {
        td {
          padding: 15px;
          .closeButtonNormal {
            padding: 6px 17px;
            line-height: 24px;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: none;
            border-radius: 0;
          }
        }
      }
    }
  }
}