.pfg-forms {
  h3 {
    display: none;
  }
  fieldset {
    margin-top: 30px;
    .form-group {
      label {
        &.input {
          display: inline-block;
          margin-top: 0;
          margin-right: 10px;
        }
        &[for="field_captcha"] {
          opacity: 0;
        }
      }
      input,
      textarea {
        border: 1px solid $cc-gris;
        background-color: $cc-blanc;
        padding: 5px 12px;
        font-size: 14px;
        color: $cc-gris-fonce;
        &:focus {
          outline: none;
          border: 1px solid $cc-noir;
          color: $cc-gris-fonce;
        }
      }
    }
  }
}