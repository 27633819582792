#header {
  .navfullwidth {
    background-color: $cc-dore;
    font-family: $cc-myriadpro;
    position: relative;

    .top-menu {
      a {
        cursor: pointer;

        &[data-depth="0"] {
          &:hover {
            color: $cc-bleu;
          }
        }

        &[data-depth="1"] {
          &:hover {
            color: $cc-bleu;
          }
        }
      }
    }
  }

  #menu-icon {
    color: $cc-bleu;
  }
}