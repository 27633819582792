@import '../../mixins/font-face';

/* Fonts ajoutées */
@include font-face('MyriadPro', './fonts/MyriadPro-Regular');
$cc-myriadpro: 'MyriadPro', sans-serif;

/* Google fonts */
$cc-volkhov: 'Volkhov', serif;
$cc-vollkorn: 'Vollkorn', serif;
$cc-courgette: 'Courgette', cursive;
$cc-opensans: 'Open Sans', sans-serif;