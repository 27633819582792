#header {
  .header-nav,
  .header-top {
    background-color: $cc-blanc;
    color: $cc-bleu;
    font-family: $cc-myriadpro;
  }
  .header-nav {
    .user-info {
      &> a,
      .dropdown {
        padding: 0 20px;
        position: relative;
        display: inline;
        cursor: pointer;
        &::after {
          content: '|';
          position: absolute;
          right: 0;
          color: $cc-bleu;
          font-size: 14px;
        }
        .dropdown-toggle::after {
          display: none;
        }
        .dropdown-menu {
          min-width: 0;
          .dropdown-item {
            padding: 0 20px;
          }
        }
      }
      &:last-child {
        &> a:last-child::after {
          display: none;
        }
      }
    }
    #google_translate_element {
      margin-top: 10px;
      line-height: 20px;
    }
  }
  .header-top {
    .container {
      padding-top: 10px;
      .header_logo {
        top: -80%;
      }
    }
  }
  a {
    color: $cc-bleu;
  }
}

@media (max-width: $lg-down) {
  #header {
    .header-top {
      #_mobile_logo {
        max-width: 150px;
        @media (max-width: $md-down) {
          max-width: 130px;
        }
      }
    }
    .header-nav {
      .right-nav {
        #cc-liens-top {
          display: none;
        }
        #google_translate_element {
          /*display: none;*/
          margin-top: 30px;
        }
      }
      #mobile_top_menu_wrapper {
        height: auto;
        #_mobile_currency_selector {
          display: none;
        }
        #_mobile_user_info {
          margin-top: 10px;
          float: none !important;
          .user-info {
            padding: 0;
            width: 100%;
            float: none;
            a {
              display: block !important;
              padding: 10px;
              border-bottom: 1px solid #efefef;
              line-height: 20px;
              &::after {
                display: none;
              }
              i {
                display: none;
              }
            }
          }
        }
      }
    }
  }

}