.btn-primary {
  background-color: $cc-dore;
  transition: background-color .2s;
  &.small {
    font-size: 12px;
    padding: 3px 10px;
  }
}
textarea {
  border: 1px solid $cc-gris;
}