#left-column {
  #amazzing_filter {
    padding: 0;
    box-shadow: none;
    .block_content {
      padding: 10px 15px;
      #af_form {
        .af_filter {
          padding: 0;
          border: 0 none;
          .af_subtitle {
            font-size: 14px;
            cursor: default;
            &::before {
              display: none;
            }
          }
          .af_filter_content {
            ul {
              li {
                padding: 0;
                label {
                  font-size: 13px;
                  color: $cc-noir;
                  margin: 0;
                }
                &.af-parent-category {
                  > label {
                    display: none;
                  }
                  ul.child-categories {
                    margin: 0;
                  }
                }
              }
            }
          }
          &.type-1,
          &.type-2 {
            &:not(.full) {
              .af_filter_content {
                max-height: 120px;
              }
            }
          }
          .toggle-cut-off {
            position: static;
            span {
              color: $cc-dore;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}