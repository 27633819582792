.block_newsletter {
  form {
    input {
      &[type="text"] {
        background-color: $cc-bleu-fonce;
        color: $cc-gris-footer;
      }
      &.btn {
        background-color: $cc-bleu-fonce;
      }
    }
  }
}