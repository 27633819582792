#left-column {
  .block-categories.block {
    .block_title {
      background-color: $cc-bleu;
      font-size: 14px;
      padding: 15px;
    }
    .category-sub-menu {
      li {
        a {
          font-size: 13px;
          color: $cc-noir;
        }
      }
    }
  }
}