#contact {
  #contact-rich {
    hr {
      margin: .3rem 0;
    }
    img,
    iframe {
      margin-top: 10px;
    }
  }
}