.products,
#products .products {
  .product_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li.product_item {
      flex: 0 0 auto;
      float: none;
      .thumbnail-container {
        border: 1px solid $cc-dore;
        max-width: 279px;
        .product-thumbnail {
          &.rupture {
            opacity: .4;
          }
        }
        .product-availability {
          position: absolute;
          top: 10px;
          right: 5px;
          font-weight: bold;
          .product-unavailable {
            color: #ff9a52;
          }
        }
      }
      .product-miniature {
        .comments_note {
          display: none;
        }
        @import '../includes/product-flags';
        .product-flags {
          .on-sale,
          .new {
            left: -40px;
            transition: all 0.7s ease 0s;
          }
        }
        .product-actions {
          padding-left: 40px;
          line-height: 40px;
          color: $cc-dore;
          &::before {
            background: url(../img/picto-panier-dore.png) no-repeat center;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: ' ';
            width: 40px;
            height: 40px;
          }
          .add-to-cart {
            padding-left: 0;
            &:hover {
              color: $cc-dore;
            }
            .material-icons {
              display: none;
            }
          }
        }
        .discount-product {
          display: none;
        }
        .product-title {
          a {
            font-size: 14px;
            font-weight: bold;
          }
        }
        .product-reference {
          color: #000;
          font-size: 13px;
        }
        .regular-price {
          color: $cc-dore;
          font-size: 15px;
        }
      }
      &:hover {
        .product-miniature {
          .product-flags {
            .new,
            .on-sale {
              left: 0;
            }
          }
        }
      }
    }
  }
  .list {
    li {
      .product-actions {
        background-color: #54535a;
        &::before {
          top: -3px !important;
        }
      }
      .add-to-cart,
      .quick-view {
        background-color: #54535a !important;
        border: 1px solid #54535a !important;
        color: #ddbc78;
        font-weight: normal;
        text-transform: none;
        padding: 5px 15px;
        &:hover {
          color: $cc-dore;
        }
      }
      .product-actions .add-to-cart {
        padding-left: 0 !important;
      }
    }
  }
}