#footer {
  .footer-container {
    .block-contact.links {
      li {
        a {
          padding-left: 0;
          background-image: none;
          text-transform: none;
        }
      }
    }
  }
}