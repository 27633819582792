#product,
.quickview {
  color: $cc-noir;
  .product_left {
    .product-leftside {
      @import '../includes/product-flags';
      .product-cover {
        border: 1px solid $cc-dore;
        &::after {
          display: block;
          position: absolute;
          bottom: 5px;
          right: 5px;
          width: 26px;
          height: 26px;
          background-image:url(../img/picto-loupe-produit.png);
          content: ' ';
        }
        #product-availability {
          position: absolute;
          top: 10px;
          right: 5px;
        }
      }
    }
  }
  .product_right {
    .productpage_title {
      margin-bottom: 30px;
    }
    .product-reference {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 20px;
      color: $cc-noir;
      label {
        font-weight: bold;
        color: $cc-noir;
      }
    }
    .product-prices {
      div {
        margin: 0 0 5px;
      }
      .pu {
        font-family: $cc-volkhov;
        font-size: 20px;
        margin-right: 10px;
        line-height: 1.2;
      }
      .product-discount {
        .regular-price {
          color: $cc-dore;
          font-family: $cc-volkhov;
        }
      }
      .product-price {
        .current-price {
          font-size: 20px;
          vertical-align: middle;
          .discount {
            display: none;
          }
        }
      }
      .tax-shipping-delivery-label {
        display: none;
      }
    }
    .product-information {
      color: $cc-noir;
      padding-left: 0;
      padding-right: 0;
      .product-actions {
        table.combinations {
          width: 100%;
          margin-bottom: 40px;
          tr {
            border-left: 1px solid $cc-gris;
            border-right: 1px solid $cc-gris;
            &.noborder {
              border-left: 0 none;
              border-right: 0 none;
              border-top: 1px solid $cc-gris;
              td {
                border: 0 none !important;
                background-color: transparent !important;
              }
              th {
                font-weight: normal;
              }
            }
            th, td {
              text-align: center;
              font-size: 12.5px;
              &.nowrap {
                white-space: nowrap;
              }
            }
            th {
              padding: 10px;
              @media (max-width: $sm-down) {
                padding: 5px;
                font-size: 13px;
              }
              text-align: center;
              font-weight: bold;
              color: $cc-blanc;
              background-color: $cc-bleu;
            }
            td {
              padding: 10px 15px;
              @media (max-width: $sm-down) {
                padding: 5px;
                font-size: 13px;
              }
              input.combination-quantity {
                width: 50px;
                border: 1px solid $cc-gris;
                text-align: center;
              }
            }
            &:nth-child(even) {
              td {
                background-color: $cc-gris-clair;
              }
            }
            &:nth-child(odd) {
              td {
                border-left: 1px solid $cc-gris;
                border-right: 1px solid $cc-gris;
              }
            }
          }
        }
        .product-add-to-cart {
          .product-quantity {
            textarea {
              border: 1px solid $cc-gris;
              padding: 5px 10px;
            }
            .add {
              float: right;
            }
          }
        }
      }
    }
  }
  .product-tabcontent {
    .tabs .nav-tabs .nav-item a {
      font-weight: bold;
      color: $cc-dore;
    }
  }
  .lien-retour {
    display: inline-block;
    margin-top: 40px;
    position: relative;
    padding-left: 60px;
    line-height: 30px;
    height: 30px;
    font-size: 15px;
    &::before {
      position: absolute;
      font-family: FontAwesome;
      content: '\f104';
      left: 0;
      border: 1px solid $cc-gris;
      padding: 0 5px;
      width: 30px;
      text-align: center;
      font-size: 25px;
    }
  }
  .productscategory-products {
    margin-top: 90px;
    margin-bottom: 40px;
  }
  .modal-header {
    border: 0 none;
    padding: 0;
    position: relative;
    z-index: 9;
    .close {
      margin-top: 0;
    }
    .modal-title {
      padding: 15px;
    }
  }
}


.quickview {
  .product_left {
    @media (min-width: $md-up) and (max-width: $lg-down) {
      width: 30%;
    }
  }
  .product_right {
    @media (min-width: $md-up) and (max-width: $lg-down) {
      width: 70%;
    }
  }
}

#product {
  .product_left {
    @media (min-width: $md-up) and (max-width: $lg-down) {
      width: 30%;
    }
  }
  .product_right {
    @media (min-width: $md-up) and (max-width: $lg-down) {
      width: 70%;
    }
  }
}