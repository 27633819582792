#carousel {
  margin-bottom: 0;
  .carousel-inner {
    margin-bottom: 0;
    .carousel-item {
      a {
        margin-bottom: 0;
        figure {
          margin-bottom: 0;
          figcaption {
            left: auto;
            right: 0;
            max-width: 50%;
            width: 50%;
            height: 100%;
            text-align: center;
            top: 0;
            color: $cc-noir;
            padding: 0 50px;
            @media (max-width: $xl-down) {
              padding: 0 10px;
            }
            .inner {
              max-width: 500px;
              width: 100%;
              height: 100%;
              position: relative;
              .top, .bottom {
                @media (min-width: $sm-up) {
                  position: absolute;
                }
                max-width: 500px;
                width: 100%;
              }
              .top {
                @media (min-width: $sm-up) {
                  bottom: 50%;
                }
              }
              .bottom {
                top: 50%;
              }
              em {
                display: inline-block;
                padding: 10px 0;
                border-top: 1px solid $cc-noir;
                border-bottom: 1px solid $cc-noir;
                font-family: $cc-courgette;
                color: $cc-dore;
                font-style: normal;
                font-size: 22px;
                margin-bottom: 40px;
                @media (max-width: $xl-down) {
                  margin-bottom: 20px;
                }
                @media (max-width: $lg-down) {
                  margin-bottom: 10px;
                  padding: 5px 0;
                  font-size: 18px;
                }
                @media (max-width: $md-down) {
                  padding: 3px 0;
                  font-size: 15px;
                }
                @media (max-width: $sm-down) {
                  font-size: 13px;
                }
              }
              h2 {
                font-family: $cc-volkhov;
                font-size: 30px;
                font-weight: normal;
                margin-bottom: 15px;
                @media (max-width: $lg-down) {
                  font-size: 20px;
                }
                @media (max-width: $md-down) {
                  font-size: 15px;
                }
              }
              .caption-description {
                margin-top: 50px;
                margin-bottom: 40px;
                font-size: 14px;
                @media (max-width: $xl-down) {
                  margin-top: 30px;
                  margin-bottom: 30px;
                }
                @media (max-width: $lg-down) {
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 13px;
                }
                @media (max-width: $md-down) {
                  margin-top: 10px;
                  margin-bottom: 10px;
                  font-size: 11px;
                  line-height: 15px;
                }
                @media (max-width: $sm-down) {
                  display: none;
                }
                p {
                  color: $cc-noir;
                }
              }
              button {
                @media (max-width: $sm-down) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}