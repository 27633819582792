#left-column {
  .sidebar-specials {
    line-height: 18px;
    .products {
      margin-bottom: 0;
      .product-item {
        .right-part {
          .product-title {
            line-height: 18px;
            a {
              font-weight: bold;
              color: $cc-noir;
            }
          }
          .reference {
            color: $cc-noir;
            font-size: 13px;
          }
        }
      }
    }
  }
}