body#checkout  {
  #checkout-addresses-step {
    #delivery-addresses {
      .address-item {
        header.h4 {
          margin-bottom: 0;
          .custom-radio {
            display: none;
          }
          .address-alias.h4 {
            display: none;
          }
          .address {
            margin: 0;
          }
        }
        .address-footer {
          display: none;
        }
      }
    }
    .add-address {
      display: none;
    }
  }
  #checkout-delivery-step {
    #delivery_message {
      padding: 5px 10px;
    }
  }
  #checkout-payment-step {
    .payment-options {
      display: none;
    }
  }
}