#left-column {
  .block {
    .block_title,
    .title_block {
      background-color: $cc-bleu;
      font-size: 14px;
      padding: 15px;
      color: $cc-blanc;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 0px;
      cursor: default;
    }
  }
  a {
    color: $cc-dore;
    transition: all .2s;
    &:hover {
      color: $cc-bleu;
    }
  }
}