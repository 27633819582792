$cc-blanc: #fff;
$cc-bleu: #38375d;
$cc-bleu-fonce: #323249;
$cc-bleu-gris: #383741;
$cc-dore: #bea76a;
$cc-gris: #e0e0e0;
$cc-gris-clair: #f5f5f5;
$cc-gris-footer: #ececec;
$cc-gris-fonce: #383741;
$cc-noir: #2a2a2a;
$cc-vert-clair: #e1ebd1;