.page-customer-account {
  .page-header {
    display: none;
  }
  #main {
    #private_shop_message {
      float: none;
      text-align: center;
      padding: 0;
      border: 0 none;
      margin-bottom: 20px;
      border-bottom: 1px solid $cc-gris;
      padding-bottom: 15px;
      i {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
    .login-form,
    .no-account {
      padding-bottom: 10px;
      h2 {
        text-align: center;
        margin: 20px 0;
      }
    }
    .login-form {
      @media (max-width: $lg-down) {
        margin-bottom: 20px;
        padding-bottom: 40px;
        border-bottom: 1px solid $cc-gris;
      }
    }
    .no-account {
      font-size: 14px;
      h2 {
        margin-bottom: 40px;
      }
      p {
        margin-bottom: 10px;
      }
      button {
        margin-top: 30px;
      }
    }
  }
}