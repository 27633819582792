#cczoneinfo {
  margin-bottom: 40px;
  .zoneinfo {
    padding: 15px;
    margin-bottom: 15px;
    background-size: cover;
    .texte {
      text-align: center;
      border: 15px solid;
      width: 65%;
      color: $cc-bleu;
      em {
        text-decoration: none;
        display: inline-block;
        border-bottom: 1px solid $cc-bleu;
        font-size: 20px;
        font-style: normal;
        font-family: $cc-courgette;
        padding-bottom: 5px;
        margin-bottom: 20px;
      }
      h1, h2 {
        font-family: $cc-volkhov;
        font-weight: normal;
        color: $cc-noir;
      }
      h1 {
        font-size: 33px;
      }
      h2 {
        font-size: 28px;
      }
      pre {
        font-family: $cc-opensans;
        font-size: 15px;
        text-transform: uppercase;
        display: inline-block;
        padding: 10px 25px;
        border: 1px solid $cc-bleu;
        margin-top: 40px;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: bold;
        -webkit-transition: all .2s;
        background-color: $cc-bleu;
        color: $cc-blanc;
        &:hover {
          background-color: transparent;
          color: $cc-bleu;
        }
      }
    }
    &.zoneinfo-small.zoneinfo-1 {
      .texte {
        background-color: $cc-blanc;
        border-color: $cc-bleu;
        float: right;
        padding: 30px 15px;
      }
    }
    &.zoneinfo-small.zoneinfo-2 {
      .texte {
        background-color: $cc-vert-clair;
        border-color: $cc-vert-clair;
        float: left;
        padding: 30px 0;
      }
    }
    &.zoneinfo-large {
      .texte {
        background-color: $cc-blanc;
        border-color: $cc-bleu;
        float: left;
        width: 50%;
      }
    }
  }
}