#cchomenonconnecte {
  margin-top: 90px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  padding: 0 70px;
  @media (max-width: $md-down) {
    padding: 0;
  }
  h1 {
    margin-bottom: 50px;
  }
  .bloc-inscription {
    border-top: 1px solid $cc-dore;
    background-color: $cc-gris-clair;
    padding: 30px;
    margin: 90px auto;
    max-width: 640px;
    color: $cc-noir;
    font-size: 15px;
    h2 {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
  .btn {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    text-transform: uppercase;
  }
}

#left-column {
  #cchomenonconnecte {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height:20px;
    h1 {
      font-size: 18px;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-family: $cc-opensans;
      font-weight: bold;
      padding: 0;
      span {
        display: inline;
        border: 0 none;
        padding: 0;
      }
    }
    .bloc-login {
      border-top: 1px solid $cc-dore;
      background-color: $cc-gris-clair;
      font-size: 13px;
      padding: 15px;
    }
    .bloc-inscription {
      margin: 30px auto;
      font-size: 13px;
      padding: 15px;
      h2 {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    .btn {
      margin-top: 5px;
      color: $cc-blanc;
    }
  }
}