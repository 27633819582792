.product_list {
  li.product_item.col-lg-2.product-mini {
    @media (min-width: $lg-up) {
      width: 16.6667%;
    }
    @media (max-width: $lg-down) {
      width: 33.3333%;
      .product-miniature .h3.product-title a {
        font-size: 13px;
      }
    }
  }
}