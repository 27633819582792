#cc-categories-carousel {
  #ctcmsblock {
    .cmsblock {
      #cmsblock-carousel {
        padding: 0 30px;
        background-color: $cc-bleu !important;
        .cmsbanner {
          padding: 20px 10px;
          .content {
            border: 1px solid #eff0de;
            padding: 0;
            text-align: center;
            display: block;
            float: none;
            height: 50px;
            line-height: 50px;
            .title {
              font-family: $cc-volkhov;
              font-size: 20px;
              text-transform: none;
            }
          }
          &:hover {
            background-color: $cc-bleu;
            .content {
              border: 1px solid $cc-dore;
            }
          }
        }
      }
      .owl-carousel {
        .owl-item {
          background-color: $cc-bleu !important;
        }
      }
      .customNavigation {
        display: block;
      }
    }
  }
  .customNavigation {
    a {
      top: calc(50% - 14px);
    }
  }
}

@media (max-width: $lg-down) {
  #cc-categories-carousel {
    #ctcmsblock {
      .cmsblock {
        #cmsblock-carousel {
          .cmsbanner {
            padding: 10px 5px;
            .content {
              height: 40px;
              line-height: 40px;
              .title {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-down) {
  #cc-categories-carousel {
    #ctcmsblock {
      .cmsblock {
        #cmsblock-carousel {
          .cmsbanner {
            .content {
              height: 35px;
              line-height: 35px;
              .title {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
