#home-page-tabs {
  .nav-item .nav-link {
    margin: 0 22.5px;
  }
}
.bestseller-products,
 .special-products-cc {
   .products-section-title {
     display: none;
   }
 }