#header {
  .blockcart {
    color: $cc-bleu;
    border: 1px solid $cc-bleu;
    background-image: url(../img/picto-panier.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 10px;
    padding-left: 25px;
    color: $cc-bleu;
    .title {
      color: $cc-bleu;
    }
    .cart-products-count {
      color: $cc-bleu;
    }
    > .blockcart-header > a .material-icons {
      display: none;
    }
    .cart_block {
      .cart-item {
        cursor: default;
        .cart-info {
          .remove-from-cart {
            .material-icons {
              color: $cc-bleu;
            }
          }
        }
      }
      .checkout {
        button {
          font-family: $cc-opensans;
        }
      }
    }
  }
}