#cms {
  #left-column {
    @media (max-width: $md-down) {
      .sidebar-specials {
        display: none;
      }
    }
  }
  #main {
    #content {
      line-height: 24px;
      h1 {
        font-family: $cc-vollkorn;
        font-size: 40px;
        text-transform: none;
        font-weight: normal;
        padding-bottom: 20px;
        margin-bottom: 30px;
        span {
          border-bottom: 1px solid $cc-dore;
        }
      }
      img {
        margin-bottom: 20px;
      }
      div.row {
        margin-top: 30px;
        .col-md-6 {
          &:first-of-type {
            padding-right: 25px;
          }
          &:last-of-type {
            padding-left: 25px;
            /*padding-top: 31px;*/
          }
        }
      }
      .bloc-gris {
        border-top: 1px solid $cc-dore;
        background-color: $cc-gris-clair;
        padding: 30px;
      }
      ul {
        padding-left: 20px;
      }
    }
  }
}