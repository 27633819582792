#category {
  .block-category {
    h1 {

    }
  }
  #products {
    #js-product-list-top {
      .total-products {
        @media (max-width: $md-down) {
          display: none !important;
        }
      }
    }
  }
}