body {
  color: $cc-gris-fonce;
  .products-section-title {
    text-transform: none;
    background-image: none;
    padding-bottom: 30px;
    span {
      display: inline-block;
      border-bottom: 1px solid $cc-dore;
      padding-bottom: 15px;
    }
  }
  .top_button {
    span {
      background-image: url('../img/back-to-top.png');
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &:not(#index) {
    #main {
      h1.products-section-title {
        font-size: 40px;
        font-weight: normal;
        text-align: left;
        text-transform: none;
      }
    }
  }
}
@media (max-width: $md-down) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: $md-up) {
  .visible-xs {
    display: none !important;
  }
}