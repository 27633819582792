.footer-before {
  background-color: $cc-gris-clair;

  .services {
    .service {
      position: relative;
      border: 1px solid $cc-dore;
      width: calc(50% - 7.5px);
      min-height: 139px;
      @media (max-width: $sm-down) {
        width: 100%;
      }
      @media (max-width: $lg-down) {
        padding-bottom: 60px;
      }

      &.service-2 {
        margin-bottom: 0;
        @media (min-width: $sm-up) {
          margin-left: 15px;
          margin-right: 0;
          margin-top: 0;
        }
      }

      .icon-inner {
        width: 44px;
        height: 44px;
      }

      .service1-icon {
        .icon-inner {
          background-image: url(../img/reassurance-1.png);
          background-position: center;
        }
      }

      .service2-icon {
        .icon-inner {
          background-image: url(../img/reassurance-2.png);
          background-position: center;
        }
      }

      .service-content {
        @media (min-width: $lg-up) {
          max-width: calc(100% - 60px);
        }

        .content-title {
          color: $cc-bleu-gris;
        }

        .content-desc {
          color: $cc-dore;
          @media (min-width: $lg-up) {
            padding-right: 70px;
          }
        }
      }

      a {
        position: absolute;
        bottom: 20px;
        @media (min-width: $lg-up) {
          right: 20px;
        }
        @media (max-width: $lg-down) {
          margin-left: -30px;
          left: 50%;
        }
      }
    }
  }
}
