#cart {
  .product-discount {
    display: inline-block;
    .regular-price {
      font-family: $cc-volkhov;
      color: $cc-dore;
    }
  }
  .current-price {
    display: inline-block;
  }
  .has-discount .discount {
    display: none;
  }
  .product-message {
    margin-top: 5px;
  }
}