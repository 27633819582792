#header {
  .breadcrumb {
    background-image: none;
    background-color: $cc-gris-clair;
    li {
      &::after {
        content: ">";
      }
      &:last-child::after {
        display: none;
      }
    }
  }
}